<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Sit/Stand Basics</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }"> Home </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'ergo_essentials' }">Ergo Essentials</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'posture_perfect' }">Posture Perfect</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'sitting_pretty' }">Sitting Pretty</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Sit/Stand Basics</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" :to="{ name:'sitting_pretty' }" aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" :to="{ name:'typing_dos_and_donts' }" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Typing Do’s and Don’ts</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- image text start -->
      <section class="image_text_sec  bg_grey pt_50 pb_50">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="row">
                      <div class="col-md-6">
                        <div class="image_left_side">
                           <img src="../assets/images/sit_stand_desks.png" alt="img">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="image_text_right_side mw-100">
                           <h2 class="mb_22">Sitting? Standing? How about a bit of both? </h2>
                           <p class="text_black mb_30">
                             Sit/Stand desks are all the rage these days, and for good reason – they allow you to alternate between sitting 
                             and standing throughout your work day. A sit/stand desk can help ease back discomfort, give you more energy (mentally and physically) and 
                             it’s been proven that you can burn more calories during the day! However, 
                             there are several ways that you can sit and stand throughout your day with or without having a sit/stand desk.
                           </p>
                           <div>
                              <a href="#" class="btn btn_outline"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                 <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                    viewBox="0 0 31 31">
                                    <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                                    <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                       stroke-linejoin="round" stroke-miterlimit="10">
                                       <path
                                          d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                          stroke="none"></path>
                                       <path
                                          d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                          stroke="none" fill="#fff"></path>
                                    </g>
                                 </svg>
                                 Watch Video Now
                              </a>
                           </div>
                        </div>
                     </div>
                    
                  </div>
               </div>
            </div>
         </div>
      <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#accordian_secc',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      <!-- Set Yourself up for success Ends -->
      <!-- Accordion  start -->
      <section id="accordian_secc" class="accordion_sec my_80">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-8">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <img src="../assets/images/set_up_my_desk_icon.png" alt="img"> What is the best way to setup my desk?
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>
                                 <span class="font_bold ">Set your desk height</span> so 
                                 that your keyboard and mouse are at the same height as your elbows. 
                                 Your arms should hang comfortably at your sides, with your elbows bent at 
                                 90 degrees with your forearms parallel to the 
                                 floor.
                                 </p>
                                  <p>
                                 <span class="font_bold ">Keep your items close to you.</span> This includes your keyboard, mouse, phone, notebooks and any other items that you use regularly. 
                                 </p>
                              
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/how_do_i_protect_icon.png" alt="img"> How do I protect my feet, back and legs when I stand? 
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>
                               <span class="font_bold ">Wear comfortable and supportive shoes.</span>  
                              Soft soled shoes with good arch support and cushioning will help your feet, knees and lower back in the comfort zone while standing.
                              </p>
                              <p>
                                 <span class="font_bold ">Consider standing on an anti-fatigue mat.</span> 
                                 If you alternate between sitting and standing, an anti-fatigue mat will do wonders to reduce stress and pressure on your back, legs and feet. 
                              </p>
                              <p>
                                 <span class="font_bold ">Make use of either a foot bar or footrest</span> 
                                 (even a small box will do).  Try  alternating between standing on both feet, resting one foot on your support and then switching 
                                 to the other.  Your lower back be grateful for this!)
                              </p>
                           </div>
                        </div>
                     </div>

                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <img src="../assets/images/watch_icon.png" alt="img">For how long should I sit and stand during the day? 
                           </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <p>  
                              Since there is no precise answer to this question, try experimenting 
                              with different time intervals to determine what works best for you.  Here are some guidelines to help you discover your best sit-stand rhythms.
                              </p>

                              <div class="claim_text_box">
                              <ul class="pl_18 mb_0 text_balticsea">
                                 <li><p class="mb_14"><strong>Alternate</strong> between sitting and standing throughout your day.</p></li>
                                 <li><p class="mb_14"><strong>Switch positions</strong> every 45  - 90 minutes based on your preferences.</p></li>
                                 <li><p class="mb_14"><strong>Pay attention</strong> to how you are feeling in each position.</p></li>
                                 <li><p class="mb_14"><strong>Be mindful of your posture</strong> when sitting and standing.</p></li>
                                 <li><p class="mb_14"><strong>Change positions</strong> as you change work tasks. </p></li>
                              </ul>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Claim Your Space end -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Office Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'posture_perfect' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect1.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Posture Perfect</h6>
                                 <p>Enjoy having the best posture in the room. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sitting_pretty' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect2.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sitting Pretty </h6>
                                 <p>Learn how to sit in total comfort day in and day out.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'sit_stand_basics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect3.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Sit/Stand Basics</h6>
                                 <p>Do you sit or stand when you work? How about a bit of both? </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'typing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect4.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Typing Do’s and Don’ts</h6>
                                 <p>These typing tips will protect your hands and speed you up. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mousing_dos_and_donts' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect5.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mousing Do’s &amp; Don’ts</h6>
                                 <p>Healthy mousing is key to your well being and productivity. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'mindful_ergonomics' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/posture_perfect/posture_perfect6.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Mindful Ergonomics</h6>
                                 <p>Being mindful of how you do what you do is key to staying healthy. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->


         <!-- Video Modal start Here -->
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe id="closed" src="https://player.vimeo.com/video/661823396?h=15f2cd13b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Your Sit/Stand Desk"></iframe>
                   
                  </div>
      </div>

    </div>
  </div>
</div>
<!-- Video Modal End Here -->
   </div>
</template>

<script>
   export default {
      name: "sitting_pretty",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;     
      },
      methods: {
          hideModal() { 
          document.getElementById('closed').src += '';
      },
      }
   }
</script>